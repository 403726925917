/** @jsx jsx */

import { jsx, Text } from "theme-ui";
import { useCallback, useEffect, useState } from "react";
import { IconButton, Stack } from "office-ui-fabric-react";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import PropTypes from "prop-types";
import {
  validateAbhyasiId,
  validateEmail,
  validatePhoneNumber,
} from "@heartfulnessinstitute/react-hfn-forms/dist/validations";

const emailOption = "email";
const phoneOption = "phone";
const idOption = "abhyasiid";

const keywordLabels = {
  [emailOption]: "Email",
  [phoneOption]: "Mobile",
  [idOption]: "Abhyasi ID",
};
const keyOptions = Object.entries(keywordLabels).map(([name, label]) => ({
  name,
  label,
}));

// const colorsBlueShades = {
//   shade6: "#D3F3FF",
//   shade5: "#C4E8F6",
//   shade4: "#A5D1E6",

//   shade0: "#04090b",
// };
const AbhyasiSearchEMIBase = ({ onNewValue }) => {
  const [emi, setEmi] = useState(idOption);

  const [value, setValue] = useState("");
  const [valid, setValid] = useState(false);
  const clearValue = useCallback(() => {
    setValue("");
    setValid(false);
  }, [setValue, setValid]);

  const onSearch = useCallback(() => {
    if (value && valid) {
      onNewValue(value);
      clearValue();
    }
  }, [value, valid, clearValue, onNewValue]);

  const updateValue = useCallback(
    (v) => {
      if (emi === emailOption) {
        setValue(v.toLowerCase());
        if (validateEmail(v)) {
          setValid(true);
          return;
        }
      } else if (emi === idOption) {
        if (
          v.length > 9 ||
          !(
            validateAbhyasiId(v + "AAAAAA999".substring(v.length)) ||
            validateAbhyasiId(v + "B99999999".substring(v.length))
          )
        ) {
          return;
        }
        setValue(v.toUpperCase());
        if (validateAbhyasiId(v)) {
          setValid(true);
          onNewValue(v.toUpperCase());
          clearValue();
          return;
        }
      } else if (emi === phoneOption) {
        setValue(`+${v.replace(/\D/g, "")}`);
        if (validatePhoneNumber(v) && v[0] === "+" && v.length > 11) {
          setValid(true);
          return;
        }
      }
      setValid(false);
    },
    [setValid, setValue, clearValue, onNewValue, emi]
  );

  useEffect(() => {
    setValue(emi === phoneOption ? "+91" : "");
  }, [emi, setValue]);

  return (
    <div sx={{ p: 1, my: 1, backgroundColor: "#D3F3FF" }}>
      <div>
        <Stack horizontal horizontalAlign="center">
          <DynamicField
            type="select"
            name="emi"
            label=""
            value={emi}
            onChange={setEmi}
            props={{
              styles: {
                title: { background: "#BBCCFF" },
              },
            }}
            options={keyOptions}
          />
          <DynamicField
            props={{ autoFocus: true }}
            name="value"
            value={value}
            label=""
            onChange={updateValue}
            type={emi}
          />
          <IconButton
            disabled={!valid}
            iconProps={{ iconName: "Search" }}
            onClick={onSearch}
            style={{
              backgroundColor: "#BBCCFF",
            }}
          />
        </Stack>
        <div sx={{ height: "1em" }}>
          {emi === phoneOption && value && !valid && (
            <Text variant="description">
              Please enter phone in format: +919900XX0099
            </Text>
          )}

          {emi !== phoneOption && (
            <Text variant="description">
              {value &&
                value.length > 5 &&
                !valid &&
                `Please enter valid ${keywordLabels[emi]}`}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

AbhyasiSearchEMIBase.propTypes = {
  onNewValue: PropTypes.func.isRequired,
};
export default AbhyasiSearchEMIBase;
