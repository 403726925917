/** @jsx jsx */
import { jsx } from "theme-ui";

import { Stack, StackItem } from "office-ui-fabric-react";

import AbhyasiEntity from "../AbhyasiEntity";
import renderPersonResultPlain from "./renderPersonResultPlain";

const renderPersonResult = (item, showRef) => {
  return (
    <Stack horizontal>
      <StackItem>
        <AbhyasiEntity id={item.id} display={null} item={item} />
      </StackItem>
      <StackItem grow>{renderPersonResultPlain(item, showRef)}</StackItem>
    </Stack>
  );
};

export default renderPersonResult;
