/** @jsx jsx */

import { jsx, Spinner, Alert } from "theme-ui";
import { useCallback, useState } from "react";
import { DefaultButton } from "office-ui-fabric-react";
import PropTypes from "prop-types";
import {
  validateAbhyasiId,
  validateEmail,
  validatePhoneNumber,
} from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import useSearchAbhyasiEMI from "../../SrcmModels/useSearchAbhyasiEMI";
// import useZoneForCenter from "../../SrcmEntities/Render/useZoneForCenter";
import RenderSearchResult from "../RenderSearchResult";
import AbhyasiSearchEMIBase from "./base";

const emailOption = "email";
const phoneOption = "mobile";
const idOption = "ref";

// const colors = {
//   shade6: "#D3F3FF",
//   shade5: "#C4E8F6",
//   shade4: "#A5D1E6",

//   shade0: "#04090b",
// };
const AbhyasiSearchEMI = ({ storeKey, onRetrieve }) => {
  const { isFetching, result, errorMessage, refresh /* reset */ } =
    useSearchAbhyasiEMI(storeKey);
  const [skey, setSkey] = useState("");
  // const zone = useZoneForCenter({ centerId: result?.srcm_group?.id });

  const onConfirmAccept = useCallback(() => {
    onRetrieve(result);
  }, [result, onRetrieve]);

  const onSearch = useCallback(
    (value) => {
      setSkey(value);
      if (value) {
        if (validateAbhyasiId(value.toUpperCase())) {
          refresh(idOption, value.toUpperCase());
        } else if (validatePhoneNumber(value)) {
          refresh(phoneOption, value);
        } else if (validateEmail(value.toLowerCase())) {
          refresh(emailOption, value.toLowerCase());
        }
      }
    },
    [refresh, setSkey]
  );

  return (
    <div sx={{ p: 1 }}>
      <AbhyasiSearchEMIBase onNewValue={onSearch} />

      {isFetching && <Spinner />}
      {errorMessage && <Alert sx={{ my: 1 }}> {errorMessage} </Alert>}

      {result && (
        <div sx={{ px: 3 }}>
          <RenderSearchResult
            skey={skey}
            record={result}
            renderSelectBtn={() => (
              <DefaultButton
                primary
                iconProps={{ iconName: "Accept" }}
                text="Confirm & Add to Team"
                onClick={onConfirmAccept}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

AbhyasiSearchEMI.propTypes = {
  onRetrieve: PropTypes.func.isRequired,
  storeKey: PropTypes.string.isRequired,
};
export default AbhyasiSearchEMI;
