/** @jsx jsx */
import { Alert, jsx, Text, Badge } from "theme-ui";
import PropTypes from "prop-types";
import { useQueryParam, StringParam } from "use-query-params";
import {
  ActionButton,
  DefaultButton,
  IconButton,
  Pivot,
  PivotItem,
  Stack,
  StackItem,
} from "office-ui-fabric-react";
import React, { useCallback, useEffect, useState } from "react";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { get, noop } from "sites-common/utils/lodash";
import { convertToB64Str, decodeB64 } from "sites-common/utils/encoded";
import AbhyasiEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/AbhyasiEntity";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import useCities from "gatsby-plugin-hfn-profile/components/SrcmModels/useCities";
import ConfirmButton from "gatsby-plugin-hfn-profile/components/ConfirmButton";
import { doFormatTitle } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import SrcmSearchPage from "gatsby-plugin-hfn-profile/components/SrcmSearchPage";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import AbhyasiSearchEMI from "gatsby-plugin-hfn-profile/components/AbhyasiEMISearchBox/SearchAbhyasiEMI";
import DefaultLayout from "../../layouts/dlw-mobile";

const labels = { owners: "Team Admin", members: "Team Member" };

const AddPerson = ({ projectId, memberType, onSuccess }) => {
  const [show, { toggle: toggleForm }] = useMyBoolean(false);

  const [error, setError] = useState("");

  const { fetchSrcmApi } = useFetchSrcmApi();

  const addProjectMate = useCallback(
    (record) => {
      const methodParams = {
        partner: record.id,
      };
      setError("");
      fetchSrcmApi({
        api: `/api/v3/teams/${projectId}/${memberType}/`,
        method: "POST",
        methodParams,
      })
        .then(() => {
          toggleForm();
          onSuccess();
        })
        .catch((e) => {
          setError(e.toString());
        });
    },
    [fetchSrcmApi, setError, memberType, projectId, toggleForm, onSuccess]
  );

  return (
    <Stack horizontalAlign="end">
      {show && (
        <div sx={{ border: "solid 1px #04090b", backgroundColor: "#D3F3FF" }}>
          <AbhyasiSearchEMI
            storeKey="functionary-team-search"
            onRetrieve={addProjectMate}
          />
          {error && <Alert>{error}</Alert>}
          <ActionButton
            iconProps={{ iconName: "Cancel" }}
            text="Cancel"
            title="Cancel"
            onClick={toggleForm}
          />
        </div>
      )}
      {!show && (
        <DefaultButton
          onClick={toggleForm}
          styles={
            memberType === "owners"
              ? buttonStyles.dangerMedium
              : buttonStyles.blueMedium
          }
          text={`New ${labels[memberType]}`}
          iconProps={{ iconName: "Add" }}
        />
      )}
    </Stack>
  );
};
AddPerson.propTypes = {
  projectId: PropTypes.string.isRequired,
  memberType: PropTypes.oneOf(["owners", "members"]).isRequired,
  onSuccess: PropTypes.func,
};
AddPerson.defaultProps = {
  onSuccess: noop,
};

const RenderCityName = React.memo(({ cityId }) => {
  const cities = useCities([cityId]);

  return (
    <span title={get(cities, [cityId, "sortKey"], "-Missing-")}>
      {get(cities, [cityId, "city"], "--")}
    </span>
  );
});

const EditTeamMemberBtn = ({
  details: d1,
  teamId,
  memberId,
  memberType,
  partner,
}) => {
  const { name } = partner;
  const { fetchSrcmApi } = useFetchSrcmApi();
  const [showForm, { toggle: toggleForm }] = useMyBoolean(false);
  const [rd, setRD] = useState(d1);

  let details = {};
  try {
    details = JSON.parse(rd);
  } catch (e) {
    details = {};
  }

  const updateTeamMember = useCallback(
    (d) => {
      // alert("edit coming soon");
      if (teamId && memberId) {
        fetchSrcmApi({
          api: `/api/v3/teams/${teamId}/${memberType}/${memberId}/`,
          method: "PATCH",
          methodParams: {
            details: JSON.stringify(d),
          },
        })
          .then((data) => {
            // console.log(data)
            if (data) {
              // onUpdateTableRecord();
              setRD(data.details);
              toggleForm();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    [teamId, memberType, memberId, fetchSrcmApi, toggleForm]
  );

  const formFields = [{ name: "group", label: "Category" }];

  return (
    <Stack
      tokens={{ childrenGap: 4 }}
      horizontal
      sx={{ mt: 1, backgroundColor: "#f7f7f7", px: 2 }}
    >
      {!showForm && (
        <StackItem>
          <IconButton
            onClick={toggleForm}
            iconProps={{ iconName: "Edit" }}
            title={`Edit details for ${name}`}
          />
        </StackItem>
      )}
      {!showForm && (
        <StackItem>
          {formFields.map((fld) => (
            <div>
              <Text variant="printFormLabel">{fld.label}: </Text>
              <Text variant="printFormValue">
                {get(details, fld.name, "--")}
              </Text>
            </div>
          ))}
        </StackItem>
      )}
      {showForm && (
        <StackItem sx={{ px: 2 }}>
          <DynamicForm
            formFields={formFields}
            defaultValues={details}
            onSubmit={updateTeamMember}
            onDiscard={toggleForm}
          />
        </StackItem>
      )}
    </Stack>
  );
};
EditTeamMemberBtn.propTypes = {
  details: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  memberId: PropTypes.string.isRequired,
  memberType: PropTypes.oneOf(["owners", "members"]).isRequired,
  partner: PropTypes.shape({ name: PropTypes.string }).isRequired,
};
const DeleteTeamMemberBtn = ({
  teamId,
  memberId,
  memberType,
  partner,
  onUpdateTableRecord,
}) => {
  const { name } = partner;
  const { fetchSrcmApi } = useFetchSrcmApi();

  const deleteTeamMember = useCallback(() => {
    if (teamId && memberId) {
      // alert("delete coming soon");
      fetchSrcmApi({
        api: `/api/v3/teams/${teamId}/${memberType}/${memberId}/`,
        method: "DELETE",
      })
        .then(() => {
          // console.log(data)
          onUpdateTableRecord();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [teamId, memberType, memberId, fetchSrcmApi, onUpdateTableRecord]);

  return (
    <ConfirmButton
      title={`Removing ${name} from Team`}
      message="Are you sure you want to continue?"
      onClick={deleteTeamMember}
    >
      <IconButton
        iconProps={{ iconName: "Trash" }}
        title={`Remove ${name} from Team`}
      />
    </ConfirmButton>
  );
};
DeleteTeamMemberBtn.propTypes = {
  teamId: PropTypes.string.isRequired,
  memberId: PropTypes.string.isRequired,
  memberType: PropTypes.oneOf(["owners", "members"]).isRequired,
  partner: PropTypes.shape({ name: PropTypes.string }).isRequired,
  onUpdateTableRecord: PropTypes.func.isRequired,
};
const colDefns = (projectId, memberType, myId, onUpdateTableRecord) => [
  [
    "",
    (r) => (
      <Stack sx={{ minWidth: "300px" }}>
        <Stack horizontal horizontalAlign="space-between">
          <StackItem>
            <AbhyasiEntity
              id={r.partner.id}
              display={r.partner.ref || "SEEKER"}
            />
          </StackItem>
          <StackItem>
            <DeleteTeamMemberBtn
              teamId={projectId}
              memberId={r.id}
              memberType={memberType}
              partner={r.partner}
              onUpdateTableRecord={onUpdateTableRecord}
            />
          </StackItem>
        </Stack>
        <StackItem sx={{ px: 3 }}>
          <Text variant="title">{doFormatTitle(r.partner.name)}</Text>
          {myId === r.partner.id && <Badge sx={{ mx: 1 }}>myself</Badge>}
        </StackItem>
        <StackItem sx={{ px: 3 }}>
          <Text
            sx={{ textDecoration: "underline", textDecorationStyle: "dotted" }}
          >
            <RenderCityName cityId={r.partner.city || "null"} />
          </Text>
        </StackItem>
        <StackItem sx={{}}>
          <EditTeamMemberBtn
            details={r.details}
            teamId={projectId}
            memberId={r.id}
            memberType={memberType}
            partner={r.partner}
          />
        </StackItem>
      </Stack>
    ),
  ],
];

// const BlobRenderData = ({ data, setIAmAdmin }) => {
//   const { srcmProfile } = useAuth();

//   React.useEffect(() => {
//     if (data.results.find((r) => r.partner.ref === srcmProfile.abhyasiId)) {
//       if (setIAmAdmin) {
//         setIAmAdmin();
//       }
//     }
//   }, [data, setIAmAdmin, srcmProfile]);

//   const groups = useMemo(
//     () => groupBy(data.results, (r) => r.partner.city),
//     [data.results]
//   );

//   const cities = useCities(Object.keys(groups));

//   if (data.results.length === 0) {
//     return <div sx={{ my: 4 }}>--None--</div>;
//   }
//   return (
//     <table>
//       <tbody>
//         {Object.entries(groups)
//           .sort((a, b) => {
//             return cities[a[0]]?.sortKey > cities[b[0]]?.sortKey;
//           })
//           .map(([k, v]) => (
//             <React.Fragment>
//               {" "}
//               <tr>
//                 <td sx={{ pt: 3 }} colSpan={2}>
//                   <DynamicField
//                     name="city1"
//                     type="text"
//                     value={cities[k]?.sortKey}
//                     readOnly
//                   />
//                 </td>
//               </tr>
//               {v
//                 .sort((a, b) => a.partner.name > b.partner.name)
//                 .map((r) => (
//                   <tr>
//                     <td>
//                       <AbhyasiEntity abhyasiId={r.partner.ref} />
//                     </td>
//                     <td>
//                       {r.partner.name}{" "}
//                       {srcmProfile.abhyasiId === r.partner.ref && (
//                         <Badge>myself</Badge>
//                       )}
//                     </td>
//                   </tr>
//                 ))}
//             </React.Fragment>
//           ))}
//       </tbody>
//     </table>
//   );
// };

// BlobRenderData.propTypes = {
//   data: PropTypes.shape({
//     results: PropTypes.arrayOf(PropTypes.shape({})),
//   }).isRequired,
//   setIAmAdmin: PropTypes.func.isRequired,
// };

const DisplayMembers = ({ projectId, memberType, iAmAdmin }) => {
  const [vers, setVers] = useState(1);
  const onUpdateTableRecord = useCallback(() => {
    setVers((v) => v + 1);
  }, [setVers]);
  const { srcmProfile } = useAuth();
  // const blobRenderData = useCallback(
  //   (data) => <BlobRenderData data={data} setIAmAdmin={setIAmAdmin} />,
  //   [setIAmAdmin]
  // );

  return (
    <>
      {iAmAdmin && (
        <div sx={{ my: 3 }}>
          <AddPerson
            projectId={projectId}
            memberType={memberType}
            onSuccess={onUpdateTableRecord}
          />
        </div>
      )}
      {/* <SearchModelResultsAsBlob
        vers={vers}
        api={`/api/v3/teams/${projectId}/${memberType}/`}
        blobRender={blobRenderData}
      /> */}

      <div sx={{ fontSize: "0.85em" }}>
        <SrcmSearchPage
          reduxKey={`team-${projectId}-${memberType}-${vers}`}
          apiParams={{
            api: `/api/v3/teams/${projectId}/${memberType}/`,
          }}
          keyOptions={[]}
          colDefns={colDefns(
            projectId,
            memberType,
            srcmProfile.id,
            onUpdateTableRecord
          )}
          noSearchDisplay
          defaultPageSize={10}
        />
      </div>
    </>
  );
};
DisplayMembers.propTypes = {
  projectId: PropTypes.string.isRequired,
  memberType: PropTypes.oneOf(["owners", "members"]).isRequired,
  iAmAdmin: PropTypes.bool,
};
DisplayMembers.defaultProps = {
  iAmAdmin: false,
};

const ProjectTeam = () => {
  const [t] = useQueryParam("t", StringParam);
  const { fetchSrcmApi } = useFetchSrcmApi();
  const { srcmProfile } = useAuth();

  const p = JSON.parse(decodeB64(t || convertToB64Str("{}")));
  const [iAmAdmin, setIAmAdmin] = useState(false);

  const admincheck = p?.id && srcmProfile?.id && !iAmAdmin;
  const projectId = p?.id;
  const myId = srcmProfile?.id;

  useEffect(() => {
    if (admincheck) {
      setIAmAdmin(-1);
      fetchSrcmApi({
        api: `/api/v3/teams/${projectId}/owners/`,
      })
        .then((data) => {
          if (
            data &&
            data.results &&
            data.results.find((r) => r.partner.id === myId)
          ) {
            setIAmAdmin(1);
          }
        })
        .catch(() => {});
    }
  }, [fetchSrcmApi, admincheck, setIAmAdmin, projectId, myId]);

  return (
    <DefaultLayout sx={{ py: 2 }}>
      <div sx={{ mb: 2 }}>
        <ActionButton
          text="Functionary Hub"
          iconProps={{ iconName: "Back" }}
          href="/functionary-hub/"
        />
      </div>

      {!p.id && "Invalid URL Parameters"}
      {p.id && (
        <Stack sx={{ px: 1, my: 2 }} tokens={{ childrenGap: 10 }}>
          <Text variant="header">{p.name}</Text>
          <Pivot>
            <PivotItem headerText="Team Members">
              <DisplayMembers
                projectId={p.id}
                memberType="members"
                iAmAdmin={iAmAdmin === 1}
              />
            </PivotItem>
            <PivotItem headerText="Team Admins">
              <DisplayMembers
                projectId={p.id}
                memberType="owners"
                iAmAdmin={iAmAdmin === 1}
              />
            </PivotItem>
          </Pivot>
        </Stack>
      )}
    </DefaultLayout>
  );
};

export default ProjectTeam;
