/** @jsx jsx */
import { Text, jsx } from "theme-ui";
import PropTypes from "prop-types";

import { Stack, StackItem } from "office-ui-fabric-react";
import { colorShades } from "sites-common/utils/fabricStyles";
import renderPersonResult from "../SrcmEntities/Render/renderPersonDetails";

const RenderSearchResult = ({ skey, record, renderSelectBtn }) => {
  return (
    <div
      sx={{
        border: "solid 1px ",
        borderColor: colorShades.blueShade7,
        backgroundColor: colorShades.fullwhite,
        p: 2,
      }}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        tokens={{ childrenGap: 12 }}
      >
        <StackItem sx={{ p: 1 }}>
          <Text sx={{ color: colorShades.blueShade1, fontSize: "0.85em" }}>
            {skey}
          </Text>
        </StackItem>
        {renderSelectBtn && <StackItem>{renderSelectBtn(record)}</StackItem>}
      </Stack>
      <StackItem>{renderPersonResult(record)}</StackItem>
    </div>
  );
};
RenderSearchResult.propTypes = {
  skey: PropTypes.string.isRequired,
  record: PropTypes.shape({ id: PropTypes.number }).isRequired,
  renderSelectBtn: PropTypes.element,
};
RenderSearchResult.defaultProps = {
  renderSelectBtn: null,
};
export default RenderSearchResult;
